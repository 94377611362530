<template>
	<div class="card-cl" style="height: 500px; width: 100%">
		<div class="card-body">
			<h2 class="info-header">SITUS INFO</h2>
			<table class="table table-striped" v-if="info.tru_id != ''">
				<tbody>
					<tr v-for="si in siteInfo" :key="si.key">
						<td class="key">{{ si.key }}</td>
						<td class="value">
							<div v-if="si.key.toLocaleLowerCase().includes('url')">
								<a :href="(si.value as string)" target="_blank">
									Link
									<span class="fa fa-link"></span>
								</a>
							</div>
							<div v-else-if="si.key.toLocaleLowerCase().includes('date')">
								<span v-if="dayjs(si.value as string).isValid()">
									{{ cleanDate(si.value as string) }}
								</span>
							</div>
							<div v-else-if="si.key.toLocaleLowerCase() === 'assigned to'">
								<a href="javascript:;" @click="displayUserInfo($event, si.value as string)" v-if="si.value">
									View Details
								</a>
								<span v-else>
									<i>Unassigned</i>
								</span>
							</div>
							<div v-else
								:class="{ 'clipboard-copy-wrapper': ['Address', 'Parcel Num'].includes(si.key) }">
								{{ si.value }}

								<!-- Append copy button  -->
								<div v-if="['Address', 'Parcel Num'].includes(si.key) && si.value"
									class="d-inline-flex">
									<a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'"
										class="copy-to-clipboard" @click="copyTextToClipboard(si.value as string)">
										<span class="fa-regular fa-copy fa-xs"></span>
									</a>

								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<h2 class="info-header">MAILING INFO</h2>
			<table class="table table-striped" v-if="info.tru_id != ''">
				<tbody>
					<tr v-for="si in mailingInfo" :key="si.key">
						<td class="key">{{ si.key }}</td>
						<td class="value" :class="{ 'clipboard-copy-wrapper': ['Address'].includes(si.key) }">
							{{ si.value }}

							<!-- Append copy button  -->
							<div v-if="['Address'].includes(si.key) && si.value" class="d-inline-flex">
								<span class="clipboard-copy-target"
									:id="'mailing-' + (si.key as string).replace(' ', '-') || ''"
									:data-copy-text="si.value"></span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<h2 class="info-header">CANDIDATE STATUS</h2>
			<table class="table table-striped" v-if="info.tru_id != '' && info.queue">
				<tbody>
					<tr v-for="si in candidateStatus" :key="si.key">
						<td class="key">{{ si.key }}</td>
						<td class="value">
							<div v-if="si.key.toLocaleLowerCase() === 'estimated recovered amount'">
								{{ formatter.format((si.value as number)) }}
							</div>
							<div v-else-if="si.key.toLocaleLowerCase() === 'queue'">
								{{ ucwords(getQueueTextByID(si.value as string) as string) }}
							</div>
							<div v-else>
								{{ getDisplayValue(si.value) }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table class="table table-striped" v-if="info.tru_id != '' && !info.queue">
				<tbody>
					<tr>
						<td class="value">Not a Candidate</td>
					</tr>
				</tbody>
			</table>

			<a target="_blank" :href="'/admin-candidate-docket/' + info.tru_id">
				<span class="fa fa-print" aria-hidden="true"></span>
				Printer-friendly screen
			</a>
		</div>
	</div>

	<Popover ref="op">
        <UserInfo :userId="selectedUserInfo" />
    </Popover>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { ucwords, copyTextToClipboard, formatProperties, getCurrencyFormatter, getQueueTextByID } from "@/helpers/common"
import { computed, nextTick, ref, watch } from "vue";
import { situsInfoPrettyNames, filterStatus, getDisplayValue, setPageTitle, cleanDate } from "@/helpers/common"
import { useCandidateDetailsStore } from "@/stores/candidateDetails";
import dayjs from "dayjs";
import { useAPI } from "@/helpers/services/api";
import type { SitusInfo } from "@/helpers/interface/candidates";
import Popover from "primevue/popover";
import UserInfo from "./UserInfo.vue";

const api = useAPI()
const { user } = useAuth0()
const store = useCandidateDetailsStore();
const info = computed(() => (store.situsInfo))

const mailingFields = [
	"mail_full_taxroll",
	"mail_county",
];
const statusFields = [
	"queue",
	"reason",
	"unqualified_start_year",
	"lien_or_back_taxes",
	"recovered_amount",
	"snooze_until",
	"final_outcome",
]
const siteInfo = computed(() => (formatProperties(info.value, situsInfoPrettyNames, [], [...mailingFields, ...statusFields])));
const mailingInfo = computed(() => (formatProperties(info.value, situsInfoPrettyNames, mailingFields)))
const candidateStatus = computed(() => {
	/* if we have a queue, then its a candidate and we display candidate fields
		otherwise, it's just a taxroll parcel, and there's no table */
	if (info.value.queue)
		return formatProperties(info.value, situsInfoPrettyNames, statusFields.filter(x => { return filterStatus(info.value, x) }))
	else
		return []
})

const formatter = getCurrencyFormatter()
watch(() => info.value, () => {
	const address = info.value.situs_full_taxroll || info.value.parcel_num;
	const addressPrefix = address ? address + " - " : ""
	const pageName = window.location.pathname.startsWith("/taxroll") ? "Tax Roll Parcel Details" : "Candidate Details"
	setPageTitle(addressPrefix + pageName)
	markCandidateSeen(info.value)
})

const op = ref()
const selectedUserInfo = ref("")
const displayUserInfo = (event: any, userId: string) => {
    op.value.hide()
    selectedUserInfo.value = userId
    nextTick(() => {
        op.value.show(event)
    })
}

const markCandidateSeen = (data: SitusInfo) => {
	if (data && data.tru_id && user.value?.sub === data.assigned_user_id) {
		api.post("taxroll/seen", { "tru_ids": [data.tru_id] })
	}
}

</script>

<style>
@import "@/assets/candidate-details.css";
</style>
